/* Import Custom Fonts */
@font-face {
  font-family: 'Rubik-Bold';
  src: url('../public/fonts/Rubik-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik-Regular';
  src: url('../public/fonts/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NexaRustSans-Trial-Black2';
  src: url('../public/fonts/NexaRustSans-Trial-Black2.ttf') format('truetype');
}
/* General Styles */
.App {
  text-align: center;
  background-color: #000000;
  color: #fff;
  font-family: 'Rubik-Regular', sans-serif;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  position: relative;
  padding: 20px;
}

.header-content {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.HOD-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 80vmin; 
}

.logo-and-title {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-right: 42vmin; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 20vmin; /* Adjust as needed */
  pointer-events: none;
}

.App-header h1 img {
  max-height: 30vh; /* Adjust as needed */
  max-width: 100%; /* Ensure it scales proportionally */
}

.App-header h1 {
  font-size: 3rem;
  font-family: 'Rubik-Bold', sans-serif;
}

.App-header p {
  font-size: 1.5rem;
  margin: 20px 0;
  max-width: 600px;
  font-family: 'Rubik-Bold', sans-serif;
}

.contract-address {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-family: 'Rubik-Bold', sans-serif;
  color: #020202;
}

.contract-address:hover {
  text-decoration: underline;
}

.copied-message {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
}

.buttons-container {
  position: relative;
  z-index: 3; /* Ensure buttons are on top */
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.buttons img {
  margin: 10px;
  cursor: pointer;
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
}

.buttons img:hover {
  transform: scale(1.1);
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: white;
  padding: 20px;
  margin-bottom: 10vh;
}

.iframe-overlay {
  position: relative;
  width: 75vw; /* 3/4 of the page width */
  height: 75vh; /* 3/4 of the page height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.limited-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.overlay-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey out the iframe */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  z-index: 1;
}

.App-footer {
  padding: 20px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  font-size: 1rem;
}

.brick-game {
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.brick-game h2 {
  margin-bottom: 20px;
}

.brick-game input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.brick-game button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.brick-game button:hover {
  background-color: #0056b3;
}

.brick-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.brick {
  padding: 10px 20px;
  margin: 5px;
  background-color: #ffc107;
  border: 2px solid #000;
  border-radius: 5px;
  font-weight: bold;
  max-width: 100px;
  text-align: center;
  word-wrap: break-word;
}

.brick-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.brick-container .brick {
  flex: 1 1 100px;
  max-width: 100px;
  margin: 5px;
}


/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .HOD-image {
    position: static;
    transform: none;
    height: 40vmin; /* Adjust as needed */
    margin-bottom: 20px;
  }

  .logo-and-title {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .App-logo {
    height: 15vmin; /* Adjust as needed */
  }

  .App-header h1 {
    font-size: 2rem;
  }

  .App-header p {
    font-size: 1rem;
    padding: 0 20px;
  }

  .iframe-overlay {
    width: 90vw; /* Adjust for mobile */
    height: 50vh; /* Adjust for mobile */
  }

  .buttons img {
    width: 80px;
    height: 80px;
  }
}